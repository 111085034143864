@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
/* TODO: ideally, wouldn't have to set this globally but I can't find any way to
   apply this styling to HTML elements loaded from CMS.  So, these color codes
   are just `themes.customPalette.primary` and `themes.customPalette.secondary`
   respectively. */
a {
    color: #0099FF;
    text-decoration: none;
}

a:hover {
    color: #1E2A45;
}


html {
    background-color: #FFF;
}
